import {Link} from "gatsby";
import React from "react";
import Container from "../atoms/Container";
import styled from "styled-components";
import Navigation from "./Navigation";


const HeaderHolder = styled.header`
height: 60px;
display: flex;
justify-content: space-between;
align-items: center;
border-bottom: 1px solid gray;

a {
  text-decoration: none;
}
`;


function Header() {
  return (
    <Container>
      <HeaderHolder>
        <Link to="/">
          <h1>Hamish McLean</h1>
        </Link>
        <Navigation />
      </HeaderHolder>
    </Container>
  );
}

export default Header;

