import React, { Component } from "react"
import { Link } from "gatsby"
import styled from "styled-components"

const NavHolder = styled.nav`
  list-style: none;
  display: flex;
  align-items: center;
  gap: 1rem;
  li {
    line-height: 1.1;
  }
  a {
    text-decoration: none;
  }
`;

class Navigation extends Component {
  render() {
    return (
        <NavHolder>
            <li>
              <Link to="/">About</Link>
            </li>
            <li>
              <Link to="/projects/">Projects</Link>
            </li>
        </NavHolder>
    )
  }
}

export default Navigation

